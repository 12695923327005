import { navigateToUrl } from 'single-spa';

import {
    AccountInfo,
    AuthenticationResult,
    Configuration,
    PublicClientApplication,
    SsoSilentRequest,
    AuthError,
    InteractionRequiredAuthError
} from '@azure/msal-browser';

import config from './config';
import {
    auth$,
    errorState,
    loggingInState,
    storageIdTokenKey,
    unauthenticatedState,
} from './oc-auth';

const LOGIN_ROUTE = "/";
const postLogoutRedirectUri = `${config.msal.redirectUri}${config.msal.redirectUri.endsWith('/') ? 'signin' : '/signin'}`;
const arrScopes = config.msal.scopes && config.msal.scopes.split(",");
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

let tokenRenewalTimer;

const msalConfig: Configuration = {
    auth: {
        clientId: config.msal.clientId,
        authority: `${config.msal.authority}/${config.msal.tenantId}`,
        postLogoutRedirectUri: postLogoutRedirectUri,
        redirectUri: config.msal.redirectUri,
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: config.msal.cacheLocation,
        storeAuthStateInCookie: true,
    },
    system: {},
}

const loginTokenRequest = {
    scopes: [...arrScopes],
}

const silentProfileRequest = {
    scopes: [`${config.msal.clientId}/.default`],
    account: null,
    forceRefresh: false,
};

const graphRequest = {
    scopes: [...arrScopes],
    account: null,
    forceRefresh: false,
}

const msalObj: PublicClientApplication = new PublicClientApplication(
    msalConfig
);

msalObj.addEventCallback((event) => {
    if (event.error instanceof AuthError) {
        const error = event.error as AuthError;
        if (error.errorMessage.includes("invalig grant") || error.errorMessage.includes("interaction_required")) {
            console.log('Token expired or invalid. Loging out...');
            signOut();
        }
    }
});

const signIn = async () => {
    msalObj.handleRedirectPromise()
        .then(await doSignIn)
        .catch(e => console.log(e));
};

const doSignIn = async () => {
    try {
        auth$.next(loggingInState);
        const loggedInAccounts: AccountInfo[] = msalObj && msalObj.getAllAccounts();
        let response: AuthenticationResult;
        const userName = localStorage.getItem("userName");
        if (loggedInAccounts.length) {
            const ssoSilentReq: SsoSilentRequest = { ...loginTokenRequest };
            ssoSilentReq.loginHint = loggedInAccounts[0].homeAccountId;
            localStorage.setItem("userName", ssoSilentReq.loginHint);
            response = await msalObj.ssoSilent(ssoSilentReq);
        } else if (userName) {
            if (!userName) {
                localStorage.setItem("userName", userName);
            }
            const silentProfile = {
                scopes: [...arrScopes],
                loginHint: userName,
            };
            try {
                const acqureTokenObj = await msalObj.ssoSilent(silentProfile);
                return acqureTokenObj;
            } catch (e) {
                response = await msalObj.loginPopup(loginTokenRequest);
                // throw e;
            }
        } else {
            response = await msalObj.loginPopup(loginTokenRequest);
        }
        // at sign in
        await refreshAccessToken();
    } catch (error) {
        auth$.next(errorState(error.message));
    }
};

const setTokens = (
    ocAccessTokenResult: AuthenticationResult,
    graphAccessTokenResult: AuthenticationResult
) => {
    checkTokenExpiration()
    localStorage.setItem(storageIdTokenKey, ocAccessTokenResult?.accessToken);
    localStorage.setItem("userName", ocAccessTokenResult?.account?.username);
    // const userRoles = getUserRoles(ocAccessTokenResult);
    const user = {
        idtoken: ocAccessTokenResult.idToken,
        accessToken: ocAccessTokenResult?.accessToken,
        graphAccessToken: graphAccessTokenResult?.accessToken,
        name: ocAccessTokenResult?.account.name,
        username: ocAccessTokenResult?.account?.username,
        // roles: userRoles,
        error: "",
        pending: false,
        isAuthenticated: true,
        inActive: false
    };
    auth$.next(user);
    // Store user object into local storage for quick access elsewhere
    localStorage.setItem('user', JSON.stringify(user));
};

const refreshAccessToken = async () => {
    msalObj.handleRedirectPromise()
        .then(await refreshAccessTokenAsync)
        .catch(e => console.log(e));
};

const getUser = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.isAuthenticated) {
        return user;
    }
    return null;
};


const getUserRoles = (
    ocAccessTokenResult: AuthenticationResult
): string[] | undefined => {
    // The roles are usually stored in the 'roles' claim 
    // But it might be different based on your auth configuration
    return ocAccessTokenResult.idTokenClaims['roles'];
};

const refreshAccessTokenAsync = async () => {
    try {
        const ocAccessTokenResponse = await fetchAccessToken(silentProfileRequest);
        const graphAccessTokenResponse = await fetchAccessToken(graphRequest);
        setTokens(ocAccessTokenResponse, graphAccessTokenResponse);
        return auth$.value;
    } catch (e: any) {
        if (e.toString().includes('interaction_required')) {
            auth$.next(errorState(e.message));
        } else {
            auth$.next(errorState(e.message));
            return auth$.value;
        }
    }
};

const isTokenExpired = (tokenExpiryTime) => {
    let currentTime = new Date()
    if(currentTime > tokenExpiryTime) {
        return true
    }
    return false
}

const fetchAccessToken = async (requestPayload) => {
    const loggedInAccounts: AccountInfo[] = msalObj && msalObj.getAllAccounts();
    const userName = localStorage.getItem("userName");
    if (loggedInAccounts.length) {
        const silentProfile = {
            ...requestPayload,
            account: loggedInAccounts[0],
        };
        try {
            const acqureTokenObj = await msalObj.acquireTokenSilent(silentProfile);
            if(isTokenExpired(new Date(acqureTokenObj.account.idTokenClaims.exp * 1000))) {
                requestPayload["forceRefresh"] = true
                let acqureTokenObj =  await fetchAccessToken(requestPayload)
                requestPayload["forceRefresh"] = false
                return acqureTokenObj;
            }
            return acqureTokenObj;
        } catch (e) {
            if (e instanceof InteractionRequiredAuthError) {
                console.log('User intraction is required to renew the token');
                signIn();
            } else {
                console.error('Token renewal error: ', e);
                signOut();
            }
        }
    } else if (userName && userName !== "undefined") {
        const silentProfile = {
            ...requestPayload,
            account: userName,
        };
        try {
            const acqureTokenObj = await msalObj.ssoSilent(silentProfile);
            return acqureTokenObj;
        } catch (e) {
            console.error(e);
            throw e;
        }
    } else if (userName === null || userName === "undefined") {
        await doSignIn();
    } else {
        const error = new Error("No accounts logged in.");
        throw error;
    }
};

const signOut = () => {
    localStorage.removeItem(storageIdTokenKey);
    localStorage.removeItem("userName");
    auth$.next(unauthenticatedState);
    if (tokenRenewalTimer) {
        clearInterval(tokenRenewalTimer);
    }
    //navigateToUrl('/home');
    msalObj.logoutRedirect();
}

const checkTokenExpiration = () => {
    const loggedInAccounts: AccountInfo[] = msalObj && msalObj.getAllAccounts();
    if (loggedInAccounts.length) {
        if (!loggedInAccounts[0]) {
            return;
        }

        const tokenExpireTime = new Date(loggedInAccounts[0].idTokenClaims.exp * 1000); // Convert to milliseconds
        const currentTime = new Date();

        if (tokenExpireTime < currentTime) {
            console.log('Token has expired. Logging out...');
            let lastActiveTime = new Date(sessionStorage.getItem("lastActiveTime"));
            if(lastActiveTime && (new Date().getTime() - lastActiveTime.getTime()) > (90*60*1000)) {
                let userInActive = unauthenticatedState
                userInActive.inActive = true;
                auth$.next(userInActive)
            } else {
                let refreshPromise = refreshAccessToken();
                refreshPromise.then((res) => {
                    refreshPromise = null;
                    return res;
                });
                auth$.next(refreshPromise as any);
            }  
        } else {
            // schedule the next just before the token expires
            const timeUntilExpiry = tokenExpireTime.getTime() - currentTime.getTime();
            if(tokenRenewalTimer) {
                clearInterval(tokenRenewalTimer);
            }
            tokenRenewalTimer = setTimeout(() => {
                checkTokenExpiration();
            }, timeUntilExpiry);
        }
    }
}

export default {
    signIn,
    signOut,
    refreshAccessToken,
    getUser,
    msalObj,
};